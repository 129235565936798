import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import Blog from "../components/blogs/blog";
import SEO from "../components/seo";
import Layout from "../containers/layout";

export const query = graphql`
  query BlogTemplateQuery($id: String!) {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    blog: sanityBlog(id: { eq: $id }) {
      _id
      author {
        
        name
      }
      _rawBody
      title
      tags
      relatedBlogs {
        id
        title
        slug {
          current
        }
      }
      mainImage {
        hotspot {
          height
          width
          x
          y
          _type
          _key
        }
        crop {
          bottom
          left
          right
          top
          _type
          _key
        }
        alt
        caption
        asset {
          _id
          url
        }
      }
      _rawExcerpt
      publishedAt
      id
      tags  
    }
  }
`;

const BlogTemplate = props => {
  const { data, errors } = props;
  const blog = data && data.blog;
  return (
    <Layout>
      {errors && <SEO title="GraphQL Error" />}
      {blog && <SEO title={blog.title || "Untitled"} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}
      {blog && <Blog {...blog} />}
    </Layout>
  );
};

export default BlogTemplate;
